<template>
  <div class="prodcost">
    <div class="flex flex-col gap-4">
      <table class="table table-sm table-fancy">
        <tr>
          <td colspan="4" class="td-filler">
            <div class="flex gap-2 justify-end text-nowrap mb-3 -m-1">
              <b-form-input
                v-model="prodcost.sale_name"
                size="sm"
                placeholder="Name"
                autofocus
                autocomplete="off"
                :disabled="isLoading"
              ></b-form-input>

              <b-form-select
                class="w-24"
                v-model="prodcost.sale_pct_option"
                :options="options.fragrance_pct"
                size="sm"
              ></b-form-select>

              <b-button
                variant="primary"
                size="sm"
                @click="saveCalculator()"
                :disabled="isLoading"
                class="d-print-none"
              >
                <span v-if="prodcost.id">
                  Update
                </span>
                <span v-else>
                  Create
                </span>
              </b-button>
            </div>
          </td>
        </tr>

        <!-- Variable Cost -->
        <tr class="head">
          <td colspan="2">
            <div class="flex gap-4 justify-between">
              <div>Variable Cost</div>
              <div>
                <b-button
                  variant="link"
                  size="sm"
                  @click="addItem('variable')"
                  class="p-0"
                  :disabled="isLoading"
                >
                  Add item
                </b-button>
              </div>
            </div>
          </td>
          <td class="text-nowrap text-right row-fit">Unit Price (CZK)</td>
          <td class="text-nowrap text-right row-fit">Unit Price (EUR)</td>
          <td class="td-filler"></td>
        </tr>
        <tr v-if="variableCosts.length === 0">
          <td colspan="4">No variable costs available</td>
        </tr>
        <tr v-for="(item, index) in variableCosts" :key="item.uuid">
          <td class="text-nowrap position-relative" colspan="2">
            <span class="calculator-index">{{ index + 1 }}</span>
            <b-button
              variant="link"
              @click="editItem(item)"
              class="flex gap-1 p-0"
              :disabled="isLoading"
            >
              <div>{{ item.product }}</div>
              <div>-</div>
              <div class="d-flex">
                <div>{{ roundNumber(item.sale_unit_quantity, 0, 2) }}</div>
                <div>
                  <span v-if="item.sale_unit === 'amount'">
                    x
                  </span>
                  <span v-else>
                    {{ item.sale_unit }}
                  </span>
                </div>
              </div>
            </b-button>
          </td>
          <td class="text-nowrap text-right row-fit">
            {{ roundNumber(item.sale_price, 2, 2) }}
          </td>
          <td class="text-nowrap text-right row-fit">
            {{ roundNumber(item.sale_price / currencyEur, 2, 2) }}
          </td>
        </tr>
        <tr>
          <td class="font-bold" colspan="2">Total</td>
          <td class="text-nowrap text-right row-fit font-bold">
            {{ roundNumber(variableCostsTotal, 2, 2) }}
          </td>
          <td class="text-nowrap text-right row-fit font-bold">
            {{ roundNumber(variableCostsTotal / currencyEur, 2, 2) }}
          </td>
        </tr>

        <!-- Fixed Cost -->
        <tr class="filler"><td colspan="4"></td></tr>
        <tr class="head">
           <td colspan="2">
            <div class="flex gap-4 justify-between">
              <div>Fixed Cost</div>
              <div>
                <b-button
                  variant="link"
                  size="sm"
                  @click="addItem('fixed')"
                  class="p-0"
                  :disabled="isLoading"
                >
                  Add item
                </b-button>
              </div>
            </div>
          </td>
          <td class="text-nowrap text-right row-fit">Price (CZK)</td>
          <td class="text-nowrap text-right row-fit">Price (EUR)</td>
        </tr>
        <tr v-if="fixedCosts.length === 0">
          <td colspan="4">No fixed costs available</td>
        </tr>
        <tr v-for="(item, index) in fixedCosts" :key="item.uuid">
          <td class="text-nowrap position-relative" colspan="2">
            <span class="calculator-index">{{ index + 1 }}</span>
            <b-button
              variant="link"
              @click="editItem(item)"
              class="p-0"
              :disabled="isLoading"
            >
              {{ item.product }}
            </b-button>
          </td>
          <td class="text-nowrap text-right row-fit">
            {{ roundNumber(item.sale_price, 2, 2) }}
          </td>
          <td class="text-nowrap text-right row-fit">
            {{ roundNumber(item.sale_price / currencyEur, 2, 2) }}
          </td>
        </tr>
        <tr>
          <td class="font-bold" colspan="2">Total</td>
          <td class="text-nowrap text-right row-fit font-bold">
            {{ roundNumber(fixedCostsTotal, 2, 2) }}
          </td>
          <td class="text-nowrap text-right row-fit font-bold">
            {{ roundNumber(fixedCostsTotal / currencyEur, 2, 2) }}
          </td>
        </tr>

        <!-- Total Cost -->
        <tr class="filler"><td colspan="4"></td></tr>
        <tr class="head">
          <td class="font-bold" colspan="2">Total Cost</td>
          <td class="text-nowrap text-right row-fit font-bold">
            {{ roundNumber(costsTotal, 2, 2) }}
          </td>
          <td class="text-nowrap text-right row-fit font-bold">
            {{ roundNumber(costsTotal / currencyEur, 2, 2) }}
          </td>
        </tr>

        <!-- Markup Pricing -->
        <tr class="filler"><td colspan="4"></td></tr>
        <tr class="head">
          <td class="row-fit text-nowrap">Markup Pricing</td>
          <td class="row-fit text-nowrap text-right">Cost (CZK)</td>
          <td class="row-fit text-nowrap text-right">Sale (CZK)</td>
          <td class="row-fit text-nowrap text-right">Sale (EUR)</td>
          <td class="td-filler"></td>
          <td class="row-fit text-nowrap text-center">VAT %</td>
          <td class="row-fit text-nowrap text-center">Margin %</td>
          <td class="row-fit text-nowrap text-center">Markup %</td>
          <td class="row-fit text-nowrap text-center">Markup</td>
          <td class="row-fit text-center">
            <div>
              <div>Markup</div>
              <div class="text-nowrap">Total Cost</div>
            </div>
          </td>
        </tr>

        <template v-for="pricing in pricings">
          <tr
            v-if="pricing.filler"
            :class="pricing.filler"
            :key="`pricing_${pricing.title}_filler`"
          >
            <td colspan="4"></td>
          </tr>
          <tr :key="`pricing_${pricing.title}`">
            <td class="text-nowrap">{{ pricing.title }}</td>
            <td class="text-nowrap text-right row-fit">
              {{ roundNumber(pricing.buy_czk, 2, 2) }}
            </td>
            <td class="text-nowrap text-right row-fit">
              <b-form-input
                v-if="pricing.sell_field"
                v-model="prodcost[`${pricing.sell_field}_czk`]"
                size="sm"
                autocomplete="off"
                :disabled="isLoading"
                class="text-right rrp-price"
              ></b-form-input>
              <span v-else>
                {{ roundNumber(pricing.sell_czk, 2, 2) }}
              </span>
            </td>
            <td class="text-nowrap text-right row-fit">
              <b-form-input
                v-if="pricing.sell_field"
                v-model="prodcost[`${pricing.sell_field}_eur`]"
                size="sm"
                autocomplete="off"
                :disabled="isLoading"
                class="text-right rrp-price"
              ></b-form-input>
              <span v-else>
                {{ roundNumber(pricing.sell_eur, 2, 2) }}
              </span>
            </td>
            <td class="td-filler"></td>
            <td class="text-center">
              {{ roundNumber(pricing.vat_rate, 0, 2) }}
            </td>
            <td class="text-center">
              {{ roundNumber(pricing.margin_pct, 2, 2) }}
            </td>
            <td class="text-center">
              {{ roundNumber(pricing.markup_pct, 2, 2) }}
            </td>
            <td class="text-center">
              {{ roundNumber(pricing.markup, 2, 2) }}
            </td>
            <td class="text-center">
              {{ roundNumber(pricing.markup_total_cost, 2, 2) }}
            </td>
          </tr>
        </template>
      </table>

      <div class="calculator-image">
        <image-box :data="prodcost" field="image_file_id" />
      </div>
    </div>

    <product-editor
      v-if="editModal.showModal"
      :data="editModal"
      :units="units"
      @save="saveItem"
      @delete="deleteItem"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { roundNumber, stringToNumber } from '@/helpers';

const ProductEditor = () => import('@/components/prodcost/ProductEditor.vue');
const ImageBox = () => import('@/components/global/ImageBox.vue');

export default {
  name: 'ProductCalculator',
  components: {
    ProductEditor,
    ImageBox,
  },
  props: {
    load: Object,
  },
  computed: {
    pricings() {
      const pricings = [];
      this.pricingFields.forEach((pricingField) => {
        const pricing = { ...pricingField };
        pricing.buy_czk = this.costsTotal * pricingField.buy_multiplier;
        pricing.sell_czk = (
          pricingField.sell_field
            ? stringToNumber(this.prodcost[`${pricingField.sell_field}_czk`])
            : this.costsTotal * pricingField.sell_multiplier
        );
        pricing.sell_eur = (
          pricingField.sell_field
            ? stringToNumber(this.prodcost[`${pricingField.sell_field}_eur`])
            : pricing.sell_czk / this.currencyEur
        );
        pricing.markup_total_cost = (
          this.costsTotal === 0
            ? 0
            : pricing.sell_czk / this.costsTotal
        );
        pricing.title = pricing.title.replace(
          '{markup_total_cost}',
          roundNumber(pricing.markup_total_cost, 0, 2),
        );
        pricing.margin_pct = this.calculateMarginPct(
          pricing.buy_czk,
          pricing.sell_czk,
          pricing.vat_rate,
        );
        pricing.markup_pct = this.calculateMarkupPct(
          pricing.buy_czk,
          pricing.sell_czk,
          pricing.vat_rate,
        );
        pricing.markup = this.calculateMarkup(
          pricing.buy_czk,
          pricing.sell_czk,
          pricing.vat_rate,
        );
        pricings.push(pricing);
      });
      return pricings;
    },
    rrpMarkup() {
      const rrpPrice = stringToNumber(this.prodcost.rrp_price_czk);
      const rrpMarkup = rrpPrice / this.costsTotal;
      return rrpMarkup;
    },
    currencyEur() {
      return this.units.currency?.EUR ?? 1;
    },
    isLoading() {
      return this.loadingCount > 0;
    },
    fixedCosts() {
      return this.items
        .filter((item) => item.cost_type === 'fixed')
        .map((item) => this.calculateCost(item))
        .sort((a, z) => a.product.localeCompare(z.product));
    },
    fixedCostsTotal() {
      return this.fixedCosts.reduce((acc, item) => (acc + item.sale_price), 0);
    },
    variableCosts() {
      return this.items
        .filter((item) => item.cost_type === 'variable')
        .map((item) => this.calculateCost(item))
        .sort((a, z) => a.product.localeCompare(z.product));
    },
    variableCostsTotal() {
      return this.variableCosts.reduce((acc, item) => (acc + item.sale_price), 0);
    },
    costsTotal() {
      return this.fixedCostsTotal + this.variableCostsTotal;
    },
  },
  data() {
    return {
      loadingCount: 0,
      options: {
        fragrance_pct: [],
      },
      units: {
        amount: {
          amount: 1,
        },
        length: {
          m: 1,
          cm: 0.01,
          mm: 0.001,
          in: 0.0254,
          ft: 0.3048,
        },
        weight: {
          kg: 1,
          g: 0.001,
          floz: 0.029574,
          oz: 0.0283495231,
          lb: 0.45359237,
        },
        currency: {
          CZK: 1,
        },
      },
      prodcost: {
        id: null,
        image_file_id: null,
        sale_name: '',
        sale_pct_option: null,
        rrp_price_czk: '',
        rrp_price_eur: '',
      },
      items: [],
      editModal: {
        showModal: false,
        item: null,
        cost_type: null,
      },
      pricingFields: [
        {
          title: 'Private label ({markup_total_cost}x total cost) excl. VAT',
          buy_multiplier: 1,
          sell_multiplier: 1.44,
          vat_rate: 0,
        },
        {
          title: 'Wholesale ({markup_total_cost}x total cost) excl. VAT',
          buy_multiplier: 1,
          sell_multiplier: 2,
          vat_rate: 0,
        },
        {
          title: 'Retail ({markup_total_cost}x total cost) -20% off incl. VAT',
          buy_multiplier: 2,
          sell_multiplier: 4,
          vat_rate: 21,
        },
        {
          title: 'Retail (5x total cost) incl. VAT',
          buy_multiplier: 2,
          sell_multiplier: 5,
          vat_rate: 21,
        },
        {
          title: 'Direct Sale ({markup_total_cost}x total cost) incl. VAT',
          buy_multiplier: 1,
          sell_multiplier: 5,
          vat_rate: 21,
        },
        {
          title: 'RRP ({markup_total_cost}x total cost) incl. VAT',
          buy_multiplier: 2,
          sell_field: 'rrp_price',
          vat_rate: 21,
          filler: 'filler-big',
        },
      ],
    };
  },
  methods: {
    calculateVatDecimal(vatRate) {
      const vatDecimal = 1 + vatRate / 100;
      return vatDecimal;
    },
    calculateMarginPct(buyPrice, sellPrice, vatRate) {
      if (buyPrice === 0) {
        return 0;
      }
      const vatDecimal = this.calculateVatDecimal(vatRate);
      const marginPct = (((sellPrice / vatDecimal) - buyPrice) / ((sellPrice / vatDecimal))) * 100;
      return marginPct;
    },
    calculateMarkupPct(buyPrice, sellPrice, vatRate) {
      if (buyPrice === 0) {
        return 0;
      }
      const vatDecimal = this.calculateVatDecimal(vatRate);
      const markupPct = (((sellPrice / vatDecimal) - buyPrice) / (buyPrice)) * 100;
      return markupPct;
    },
    calculateMarkup(buyPrice, sellPrice) {
      if (buyPrice === 0) {
        return 0;
      }
      const markup = sellPrice / buyPrice;
      return markup;
    },
    calculateMarkupTotalCost(sellPrice) {
      if (this.costsTotal === 0) {
        return 0;
      }
      const markupTotalCost = sellPrice / this.costsTotal;
      return markupTotalCost;
    },
    fetchOptions() {
      this.loadingCount++;
      this.$http
        .get('/select/groups/fragrance_pct')
        .then((res) => {
          this.options.fragrance_pct = res.body.group.options.map((option) => ({
            text: `${option.option_value}%`,
            value: option.id,
          }));
          this.options.fragrance_pct.unshift({
            text: '',
            value: null,
          });
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch options: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchCurrency() {
      this.loadingCount++;
      this.$http
        .get('/currency')
        .then((res) => {
          res.body.currencies.forEach((currency) => {
            this.$set(this.units.currency, currency.currency_code, currency.currency_rate);
          });
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch currency: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    convertUnit(quantity, unitType, unitFrom, unitTo) {
      if (unitTo === undefined) {
        const defaultUnit = Object.entries(this.units[unitType]).find((unit) => unit[1] === 1)[0];
        unitTo = defaultUnit;
      }
      const unitFromRate = this.units[unitType][unitFrom];
      const unitToRate = this.units[unitType][unitTo];
      const convertedUnit = quantity * (unitFromRate / unitToRate);
      return convertedUnit;
    },
    calculateCost(item) {
      const unitPurchase = this.convertUnit(
        item.purchase_unit_quantity,
        item.product_unit_type,
        item.purchase_unit,
      );
      const unitSale = this.convertUnit(
        item.sale_unit_quantity,
        item.product_unit_type,
        item.sale_unit,
      );
      const salePrice = (unitSale / unitPurchase) * item.purchase_price;
      const salePriceCurrency = this.convertUnit(
        salePrice,
        'currency',
        item.purchase_currency,
      );
      return {
        ...item,
        sale_price: salePriceCurrency,
      };
    },
    roundNumber(value, minimumFractionDigits, maximumFractionDigits) {
      return roundNumber(value, minimumFractionDigits, maximumFractionDigits);
    },
    addItem(costType) {
      this.editModal.item = null;
      this.editModal.showModal = true;
      this.editModal.cost_type = costType;
    },
    editItem(item) {
      this.editModal.item = item;
      this.editModal.showModal = true;
    },
    saveItem(saveItem) {
      const existingItem = this.items.find((item) => item.uuid === saveItem.uuid);
      if (existingItem) {
        Object.keys(saveItem).forEach((itemKey) => {
          existingItem[itemKey] = saveItem[itemKey];
        });
      } else {
        this.items.push(saveItem);
      }
    },
    deleteItem(deleteItem) {
      this.items = this.items.filter((item) => item.uuid !== deleteItem.uuid);
    },
    saveCalculator() {
      const data = {
        id: this.prodcost.id,
        image_file_id: this.prodcost.image_file_id,
        sale_name: this.prodcost.sale_name,
        sale_pct_option: this.prodcost.sale_pct_option,
        rrp_price_czk: stringToNumber(this.prodcost.rrp_price_czk),
        rrp_price_eur: stringToNumber(this.prodcost.rrp_price_eur),
        sale_price: this.costsTotal,
        items: this.items,
      };
      this.loadingCount++;
      this.$http
        .post('/prodcost')
        .send({ data })
        .then((res) => {
          const { record } = res.body;
          this.prodcost.id = record.id;
          this.items = record.items.map((item) => {
            item.uuid = uuidv4();
            return item;
          });
        })
        .catch((err) => {
          this.$toast.error(`Failed to save calculator: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    loadCalculator() {
      const load = JSON.parse(JSON.stringify(this.load));
      if (load.id) {
        this.loadingCount++;
        this.$http
          .get(`/prodcost/${load.id}`)
          .then((res) => {
            const { record } = res.body;
            this.prodcost.image_file_id = record.image_file_id;
            this.prodcost.sale_name = record.sale_name;
            this.prodcost.sale_pct_option = record.sale_pct_option;
            this.prodcost.rrp_price_czk = roundNumber(record.rrp_price_czk, 2, 2);
            this.prodcost.rrp_price_eur = roundNumber(record.rrp_price_eur, 2, 2);
            this.prodcost.id = record.id;
            if (load.type === 'duplicate') {
              this.prodcost.id = null;
              this.prodcost.image_file_id = null;
              this.prodcost.sale_name += ' - duplicate';
            }
            const items = record.items.map((item) => {
              item.uuid = uuidv4();
              return item;
            });
            this.items = items;
          })
          .catch((err) => {
            this.$toast.error(`Failed to save calculator: ${err.response.text}`);
          })
          .finally(() => {
            this.loadingCount--;
          });
      }
      this.$emit('loaded');
    },
  },
  created() {
    this.loadCalculator();
    this.fetchCurrency();
    this.fetchOptions();
  },
};
</script>

<style lang="scss" scoped>
  .prodcost {
    max-width: 1200px;
    position: relative;
  }

  .table-fancy {
    th,
    td {
      border: 1px solid;
      vertical-align: middle;
    }

    tr.head {
      // border-block-end: 2px solid;
      background: whitesmoke;

      &.no-border {
        border-block-end: 0;
      }
    }

    tr.filler {
      background: white;
      td {
        border: 0;
      }
      height: 11px;
    }

    tr.filler-big {
      background: white;
      td {
        border: 0;
      }
      height: 22px;
    }

    .td-filler {
      width: 11px;
      border: 0;
      background-color: white;
    }
  }

  .rrp-price {
    font-size: 14px;
  }

  .calculator-image {
    width: 380px;
  }

  .calculator-index {
    position: absolute;
    left: -25px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 20px;
    text-align: right;
    justify-content: end;
  }

  @media (min-width: 1200px) {
    .calculator-image {
      position: absolute;
      right: 25px;
      top: 0;
    }
  }

  @media print {
    .calculator-image {
      width: 300px;
      position: absolute;
      right: 25px;
      top: 55px;
    }
  }
</style>
